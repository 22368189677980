'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import { Controller, useForm } from 'react-hook-form'
import { reverseUrl } from '@repo/utilities'

import { ActionButton } from '@/_components/ActionButton'
import { FormControl } from '@/_components/FormControl'
import { InputWrapper } from '@/_components/InputWrapper'
import { Link } from '@/_i18n/routing'
import { LoginSchema } from '@/_utils/schema'
import { getTypedFormData, TypedFormData } from '@/_utils/typedFormData'

import { Inputs, LoginFormData } from '../types'

interface UpdatePasswordProps {
  signIn: (
    formData: TypedFormData<LoginFormData>
  ) => Promise<number | undefined | null>
}

export default function LoginForm({ signIn }: UpdatePasswordProps) {
  const defaultValues: Inputs = {
    email: '',
    password: '',
  }

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>({
    resolver: zodResolver(LoginSchema),
    defaultValues,
  })

  const onSubmit = async (data: Inputs) => {
    const formData = getTypedFormData<LoginFormData>()
    Object.keys(data).forEach((key) => {
      const typedKey = key as keyof Inputs
      const value = data[typedKey]
      if (value !== null) formData.append(typedKey, value)
    })
    await signIn(formData)
  }

  return (
    <form
      className='animate-in text-foreground flex w-full flex-1 flex-col justify-center gap-2 pt-8 px-2 sm:px-0'
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name='email'
        control={control}
        render={({ field: { name } }) => (
          <FormControl
            name={name}
            title='Email'
            error={errors.email}
            labelSize='text-base'
          >
            <InputWrapper
              register={register(name)}
              id={name}
              type='email'
              placeholder='you@example.com'
              required
            />
          </FormControl>
        )}
      />
      <Controller
        name='password'
        control={control}
        render={({ field: { name } }) => (
          <FormControl
            name={name}
            title='Password'
            error={errors.password}
            labelSize='text-base'
          >
            <InputWrapper register={register(name)} id={name} type='password' />
          </FormControl>
        )}
      />
      <Link
        href={reverseUrl('forgot-password')}
        className='text-right hover:text-blue-500 text-sm opacity-70 underline'
      >
        I forgot my password
      </Link>
      <div className='flex flex-1 flex-col w-full self-center'>
        <ActionButton
          type='submit'
          color='orange'
          className='my-2'
          textSize='sm:text-lg'
          fontWeight='font-normal'
          disabled={isSubmitting}
          pending={isSubmitting}
        >
          Log In
        </ActionButton>
      </div>
    </form>
  )
}
