import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { useState } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'

interface InputWrapperProps {
  id: string
  type?: string
  placeholder?: string
  register: UseFormRegisterReturn
  className?: string
  required?: boolean
  autoComplete?: string
}

export const InputWrapper = ({
  id,
  type = 'text',
  placeholder,
  register,
  className,
  required,
  autoComplete,
}: InputWrapperProps) => {
  const [showPassword, setShowPassword] = useState(false)

  const onEyeClick = (): void => {
    setShowPassword(!showPassword)
  }

  return (
    <div className={clsx('relative', className)}>
      <input
        {...register}
        id={id}
        autoComplete={autoComplete}
        type={showPassword && type === 'password' ? 'text' : type}
        className='w-full rounded-3xl border bg-white px-4 py-2 pr-10'
        placeholder={type === 'password' ? '••••••••' : placeholder}
        required={required}
      />
      {type === 'password' && (
        <button
          type='button'
          onClick={onEyeClick}
          className='absolute right-3 top-1/2 transform -translate-y-1/2'
        >
          {showPassword ? (
            <EyeIcon className='h-5 w-5 text-gray-500' />
          ) : (
            <EyeSlashIcon className='h-5 w-5 text-gray-500' />
          )}
        </button>
      )}
    </div>
  )
}
