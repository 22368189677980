'use client'

import { destroyCookie, parseCookies } from 'nookies'
import { useEffect } from 'react'
import toast from 'react-hot-toast'

export interface ToastCookieProps {
  message: string
  type?: 'information' | 'error' | 'success'
}

export const Toast = () => {
  useEffect(() => {
    const cookies = parseCookies()

    if (cookies.toast) {
      const parsedCookieToast: ToastCookieProps = JSON.parse(cookies.toast)
      const { message, type } = parsedCookieToast
      if (type === 'success') {
        toast.success(message)
      } else if (type === 'error') {
        toast.error(message)
      } else {
        toast(message)
      }

      // Clear the toast message cookie after displaying it
      destroyCookie(null, 'toast', { path: '/' })
    }
  })

  return null
}
